/* eslint-disable no-sparse-arrays */
import React, { useState } from "react";
import "./Changelog.css";
import { Helmet } from "react-helmet-async";

const Changelog = () => {
  const changelogs = [
    {
      title: "BETA 1.2.0",
      date: "Major - Ongoing Rolling Release",
      changes: [
        "Implemented a Viewer Discretion Warning system for sensitive live cameras. Streams marked as 'Censored' will now display a content warning before playback.",
        "Added dynamic warning messages sourced directly from each camera’s 'censoredwarning' property, providing clearer context on why discretion is advised.",
        "Live streams flagged as 'Censored' will no longer autoplay until the user acknowledges and accepts the content warning.",
        "Introduced a blurred thumbnail background with an overlaid content warning message and Accept button for censored streams.",
        "Implemented localStorage memory—users will only see the viewer discretion warning once per stream, even across sessions, until browser storage is cleared.",
        "Introduced a Global Search Bar across the platform, allowing users to search for live cameras and locations instantly from any page.",
        "The Search Bar features a sleek dark-themed design, smooth dropdown animations, and real-time results powered by Earf’s backend.",
        "Added channel thumbnails and partner indicators next to search results, giving users clearer context before selecting a stream.",
        "Implemented professional skeleton loaders while results load, ensuring a smooth and visually appealing experience.",
        "A friendly and professional 'No Results Found' message is now displayed when no results match the query.",
        "Search Results now clearly show the stream name, Wikidata information, country, and channel name with Earf partnered status where applicable.",
        "Selecting a search result will now open the stream in the Stream Viewer, dynamically updating relevant components and content across the page.",
        "Search Results will now show only Online Streams, FIltering out any offline streams to ensure users only see live content.",
        "Enhanced the UI for the Audio Player within the Radio Component to ensure a more responsive experience across all devices. The player now features a volume slider and has been moved from the header to a dedicated component that slides up from the bottom, making it accessible across all pages of the platform.",
        "Updated User-Agents and Version Numbers for API Calls",
        "Implemented Skeleton Loaders to the Country and Categories Browse Pages for a smoother loading experience.",
        "Earf Shot Spotter Component has successfully completed testing and is now available as a release candidate, with future plans to expand coverage beyond the United States—hopefully raising awareness of unnecessary violence around the world.",
        "Increased the Frequency in which Earf's Shot Spotter Gathers and compiles data",
        "Implemented React-Draggable across the platform.",
        "Elements like the Radio Audio Player can now be freely dragged and positioned on screen, with more draggable features coming soon.",
        "The Radio Audio Player now remembers its last position - When you reopen the player, it will appear in the exact spot you last placed it, so you don’t have to reposition it every time.",
        "The player will now correctly hide itself when a station is stopped, even if it was previously dragged elsewhere on the screen.",
        "Improved Audio Player visibility and dragging behavior.",
      ],
    },
    {
      title: "BETA 1.1.9",
      date: "March 22, 2025",
      changes: [
        "Implemented the Earf Shot Spotter Component for testing — now viewable directly on <a href='https://earf.tv/live-alerts/usa-gun-violence' target='_blank' rel='noopener noreferrer'>earf.tv/live-alerts/usa-gun-violence</a> and <a href='https://earf.tv/explore' target='_blank' rel='noopener noreferrer'>earf.tv/explore</a>, through manual location selection.",
        "Enhanced map marker functionality with dark-themed popup integration.",
        "Enabled clickable coordinates on the Alert page clicking on coordinates flies the map to the exact incident location (or the corresponding city), with a note that some locations may not detect 100% accurately.",
        "Refined error handling to prevent invalid map states.",
        "Optimized performance for dynamic data rendering on the map.",
        "Updated UI styling for consistent and responsive design across devices.",
        "Introduced detailed tooltips and hover effects for improved incident information display.",
        "Conducted cross-browser testing to ensure component stability.",
      ],
    },
    {
      title: "BETA 1.1.8",
      date: "March 21, 2025",
      changes: [
        "Downgraded the front end to React 18 and React-Dom 18 due to issues with React 19 due to the new React 19 features causing issues with the current codebase.",
        "Added location-based consent popups for users in California (CCPA), Brazil (LGPD), and the EU (GDPR), ensuring compliance with regional data protection laws.",
        "Updated the DMCA & Copyright Disclaimer, Terms of Service, and Privacy Policy.",
        "Reached a Milestone of over 1000+ cameras online at once.",
      ],
    },
    {
      title: "BETA 1.1.7",
      date: "March 20, 2025",
      changes: [
        "Added a new full-screen preloader displaying the Earf logo when the page is refreshed before showing the normal Loading state.",
        "Adjusted the footer layout for the reload loader to position the EARF branding and Verification information text at the bottom while maintaining a clean centered layout.",
        "Fixed incorrect font sizing on the Home page Video Section, which caused the channel name to display in different font sizes depending on the language of the YouTube channel.",
        "Implemented Skeleton Loaders across most loadable content, improving the visual experience while waiting for data to load. Further refinements and improvements to skeleton loaders will continue over time.",
        "Temporarily removed `tag3` (Region Tags) from all viewable areas while adjustments are being made (Exception: Home will still show tag3).",
        "Fixed various mobile styling issues across multiple components to improve responsiveness and layout consistency.",
        "Identified an issue where repeatedly refreshing a URL will cause 404 errors due to lost React state. This is currently under review for a future fix.",
      ],
    },
    {
      title: "BETA 1.1.6",
      date: "March 18, 2025",
      changes: [
        "Upgraded the front end to React 19 and React-Dom 19 and other Node packages for React 19",
        "Optimized server-side rendering (SSR) for faster page loads",
        "Improved handling of Facebook scraper requests to prevent partial content issues",
        "Applied for Increased timeout for serverless functions to improve stability",
        "Refactored Similar Videos sidebar logic for more accurate recommendations",
        "Enhanced Open Graph metadata for better link previews on social media",
        "Fixed a bug where some live cameras failed to display thumbnails",
        "Improved mobile responsiveness for the sidebar and video player",
        "Updated dependencies and security patches",
        "Added verified partner icons next to channels on the Browse Countries/Categories and Browse Stream pages that have been verified",
        "Reached a new milestone of over 1,000 online cameras at once and growing",
        "Fixed the Place Information bar to text-wrap if it overflows the video container",
        'Updated the formatting of the Loading Footer to display "Learn how" after mentioning verification.',
        "Updated the VideoView component to conditionally render the third tag without an onClick handler as it only causes a 404 error.",
        "Updated Home and the Home VideoSection to use manualdescription from the API if available, otherwise use the provided templates.",
        "The home page now displays up to 3 featured streams in the slider, giving priority to verified partnered channels. In the future, this maximum of 3 verified channels can be increased as more verified channels become available.",
        "Implemented skeleton loading in the Home Video Section and the Video View information section for a smoother loading experience.",
      ],
    },
    {
      title: "BETA 1.1.5",
      date: "March 17, 2025",
      changes: [
        "Introduced a partner image display next to the channel name for verified partner cameras.",
        "Added a 'Verified' tooltip for partner images to provide additional context.",
        "Integrated manual camera descriptions that highlight each camera’s focus while retaining Wikipedia and Wikidata links for further details.",
        "Refined small links across the platform for enhanced accessibility and a smoother user experience.",
        "Optimized the live stream interface for faster loading times and reduced latency.",
        "Enhanced tag normalization across routes to ensure consistent navigation and an intuitive search experience.",
        "Upgraded caching mechanisms to boost data retrieval speeds and reduce server load during peak traffic.",
        "Implemented bug fixes related to camera status updates and real-time viewer counts.",
        "Updated the administrative dashboard with new tools for improved monitoring and management of live cameras.",
        "Enabled full administrative control over live camera management—admins can now toggle cameras' online/offline status, modify camera information, and manage channel restrictions with ease.",
        "Implemented the new Earf Verification Process page with clear, step-by-step instructions for verifying channels and streams.",
        "Added a notice prompting users without an active camera on our site to complete the Camera Request process before proceeding with verification.",
        "Included a verification info block in the Camera Request page with a link to the Verification Page for immediate action.",
        "Updated Helmet meta tags for improved SEO and social sharing on verification and contact pages.",
        "Refined CSS styles for verification and contact pages, ensuring a responsive, mobile-friendly layout across tablets and laptops.",
        "Modified the loader footer to include a clickable EARF branding link that navigates to the Camera Request or Verification page.",
        "Added a new Discord 'Join the Community' link in the sidebar footer that adjusts for both collapsed and expanded states.",
        "People who join our Discord can now see in real time when live cameras are added via the #new-location channel via our Earfy Bot.",
        "If Earfy Bot goes offline or is offline, it may signal server or website difficulties; if it’s online in Discord, all systems should be functioning normally.",
        "Removed the View on Map button for mobile devices to improve the user experience, reduce clutter and UI displacement.",
        "Significantly increased the backend refresh rate for live and browse camera data, ensuring that active streams are updated and displayed in near real time.",
      ],
    },
    {
      title: "BETA 1.1.4",
      date: "March 10, 2025",
      changes: [
        "Added country pages to allow users to click and view detailed information about countries via the information icon next to the Camera Country Information section while browsing cameras.",
        "Improved error handling and loading states for country information to provide a smoother user experience.",
        "Enhanced data extraction and display logic for country details to ensure accurate and comprehensive information is shown.",
        "Added new tooltip messages and icons for various country links to provide additional context and information.",
        "Improved navigation and user experience for country information, making it easier for users to find and access relevant details.",
        "Refactored code for better readability and maintainability, ensuring the codebase is easier to understand and modify.",
        "Updated the Browse component to fetch country profiles and display alerts correctly.",
        "Added a pulsing animation to the alert icon in the Browse component.",
        "Improved the styling of the alert icon in the Browse component.",
        "Updated the primary color and its darker shades in the CSS variables.",
        "Added a new spinner animation for loading states.",
        "Improved the layout and styling of the Home page.",
        "Enhanced the CountryDetails component to fetch and display detailed country information.",
      ],
    },
    {
      title: "BETA 1.1.3",
      date: "March 9, 2025",
      changes: [
        "The Sidebar and Mobile Sidebar now display an alert icon next to countries with active alerts.",
        "For popular countries, the icon is shown prominently, with full alert details available upon clicking the country in its Browse profile.",
        "Alerts are now consistently displayed at the top of each country's Browse page, regardless of popularity.",
        "Updated the Contact page to inform users that it is still under development but now offers direct contact access for support, partnerships, and legal inquiries. Full completion of the Contact page is planned with or before BETA 1.2.0.",
        "Added social media links for Discord, Facebook, and X (Twitter) to improve user engagement during Contact Us page downtimes.",
        "General stability improvements and minor bug fixes to enhance overall platform performance.",
        "Added new categories and updated descriptions for existing categories to enhance engagement and global coverage",
      ],
    },
    {
      title: "BETA 1.1.2",
      date: "February 28, 2025",
      changes: [
        "Access to increased YouTube API quota in compliance with YouTube's Terms of Service and Privacy Policy as part of our YouTube Partner integration, ensuring smoother access to live stream data and real-time information.",
        "Further improved the loaders introduced in BETA 1.1.1 by enhancing smooth transitions, adding fade-in effects, and displaying contextual text to indicate the current loading process.",
        "Created an EARF Discord Server for updates, notifications, bug reports, and known issues. This provides a centralized hub for users to stay informed about platform updates, report issues, and track ongoing improvements.",
        "Introduced the Earfy Discord Bot! The bot automatically posts new camera additions in real-time, keeping users updated on the latest live streams.",
        "Enhanced Discord bot embed formatting, including improved thumbnails, descriptions, and location tagging.",
        "Added a delay mechanism in the Discord bot to ensure Open Graph metadata loads before posting, preventing missing embeds.",
        "Implemented camera category tagging in Discord posts for better organization.",
        "Added current known issues and platform improvements. All pages and components are still in beta styling but will be improved further over time.",
        "Created a Bug Reports system in Discord where users can submit detailed reports on platform issues to help with troubleshooting.",
        "Added a Suggestions & Feature Requests channel in Discord for users to request new cameras, features, and improvements to the platform.",
        "Updated the Discord server with structured channels for general chat, roadmap discussions, development updates, and user reports.",
        "Implemented Discord server rules and guidelines to ensure a welcoming and well-moderated community.",
        "Added Discord roles for better organization: `Founder & Developer`, `Developers`, `Users`, `Earfy`, `Partners`, and more, making it easier to identify different members.",
        "Fixed Helmet metadata not updating properly by ensuring it renders consistently and dynamically updates based on state changes.",
        "Ensured `HelmetProvider` is correctly implemented across the application to avoid inconsistencies in metadata rendering.",
        "Fixed canonical and Open Graph URL structures to properly encode tags and prevent malformed links, improving SEO and link previews.",
        "Replaced undefined `selectedTag` references in Helmet to prevent URL generation issues in metadata.",
        "Replaced the Earfy favicon with a modernized version that aligns better with other social media favicons for a cleaner and more polished look.",
        "Improved overall metadata handling for better SEO and social media link previews, ensuring correct image, title, and description rendering.",
        "Updated the Terms of Service, Privacy Policy, and DMCA & Copyright pages to reflect new policies and improvements. Effective dates have been updated accordingly.",
      ],
    },
    {
      title: "BETA 1.1.1",
      date: "February 18, 2025",
      changes: [
        "Replaced the default spinner with an animated pulsing YouTube logo as a loader when loading YouTube streams.",
        "Implemented a custom pulsing Earf logo as a loader for general loading states across the site.",
        "Updated the Explore Camera navigation links to properly format tags, converting spaces to hyphens (-) and encoding them for clean URLs.",
        "Fixed tag2 navigation to match the expected path structure when clicking related content.",
        "Fixed React Hooks ESLint issue caused by a conditionally placed useEffect. Now all hooks are properly structured at the top level.",
        "Ensured that loading states reset correctly when navigating between streams.",
        "Improved video fetching logic to enhance reliability and prevent unexpected failures.",
        "Optimized and cleaned up CSS by removing unnecessary comments and redundant code, reducing overall file size and improving performance.",
        "Refactored some JSX structure by removing unused comments, making the codebase cleaner and more efficient.",
      ],
    },
    {
      title: "BETA 1.1.0",
      date: "February 16, 2025",
      changes: [
        "Added new logo to fit inline with 2025 branding.",
        "Implemented the Geolocate feature on the Explore (Map) page, allowing users to find their current location and explore nearby features conveniently.",
        "Implemented Cartel/Gang Territories Overlay on the Explore (Map) page, displaying cartel-controlled areas in Mexico based on real-world data. (Mexico only at this stage)",
        "Cartel overlay dynamically adjusts visibility based on zoom level (only visible at zoom 5 or higher).",
        "Users can now gain more information and learn about cartels or gangs by clicking on the Cartel/Gang Name in the overlay. This action will open the corresponding Wikipedia page for the selected cartel or gang in a new tab, allowing for easy access to more detailed information.",
        "Refined cartel detection logic to exclude 'Unknown' areas and only display known cartel-controlled or disputed territories.",
        "Stream View now fetches streams directly by video ID rather than searching through all features.",
        "Added fallback logic to automatically display the next live stream for that location from the partner's channel if the current stream is detected offline.",
        "Enhanced the Browse functionality to provide a seamless experience when viewing live and categorized streams.",
        "Improved search results for tags to ensure comprehensive and accurate data.",
        "Streamlined backend processes to deliver faster and more reliable data for streams and categories.",
        "Revamped RainViewer integration for optimized performance and a more lightweight experience.",
        "Removed Toastify popups from RainViewer, reducing UI distractions and improving performance.",
        "Weather radar animations now transition smoother and update every 5 minutes in the background.",
        "Fixed minor bug where traffic layer was not updating correctly.",
        "Optimized video loading performance when filtering by tags.",
        "Now only displaying Countries and Categories (City, Capital Cities, Landmarks, etc.).",
        "Regions have been removed from the Category Row.",
        "Regions are no longer visible in the Browse section.",
        "Future implementation planned and started for cities that come onboard or have city-wide cameras.",
        "Updated stream path structure to improve navigation and consistency across pages.",
        "Fixed Similar Videos Sidebar to complement the new path structure and ensure related content is accurately displayed.",
        "Adjusted routing logic to align with the updated pathing system, ensuring seamless navigation between categories and streams.",
        "Removed the back button from the Radio Stations section—users can now go back by simply clicking the radio button again.",
        "Fixed SEO meta tags to properly match the new path structure, ensuring better indexing and accurate link previews for shared streams and improved overall SEO performance.",
        "Various improvements made to the admin backend stream checker for better efficiency and accuracy and ease of use.",
        "Added new admin backend feature to allow for manual stream switching in case of issues.",
        "Implemented new admin backend feature to enable or disable streams as needed.",
      ],
    },
    {
      title: "BETA 1.0.0",
      date: "December 7, 2024",
      changes: [
        "Added 350+ new live cameras from various locations around the world.",
        "Implemented a custom API to gather real-time latitude and longitude data for the International Space Station (ISS).",
        "Integrated ISS tracking on the frontend, with support for its live camera in the Stream View.",
        "Optimized API interactions for faster data retrieval and reduced latency in fetching country profiles and live camera data.",
        'Fixed inaccuracies in the "Popular Countries" feature, ensuring correct ranking of countries with the most live cameras.',
        "Enhanced backend monitoring for stream availability, adding auto-switching to alternative feeds when primary streams go offline.",
        "Launched custom APIs for weather, local time, air quality, and historical facts, integrated across the platform.",
        'Added "Today in History" facts on all load screens using a custom API.',
        "Improved platform-wide performance, reducing load times and enhancing responsiveness.",
        "Redesigned the home page with updated layouts, resized icons, and dynamic scaling for responsive screens.",
        "Refined core UI styles and layouts for consistency and adaptability across devices.",
        'Updated "Popular Countries" feature with flags and direct links to country profiles.',
        "Refined country profiles with updated flags, descriptions, and geographical details.",
        "Moved 95% of API calls and key management to the backend for improved security and key handling.",
        "Standardized location-based data handling with backend-driven weather, time, and air quality features.",
      ],
    },
    ,
    {
      title: "BETA RC1",
      date: "October 9, 2024",
      changes: [
        "The Big Bang - Earf is Created!",
        "Interactive.Earth has transformed into something greater.",
        "Hello EARF :)",
      ],
    },
  ];

  // State to track which changelog entry is expanded
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="changelog-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Changelog | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Stay updated with the latest features, improvements, and updates on Earf. See what's new and how we're evolving the platform to enhance your experience."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/changelog" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Changelog | Earf" />
        <meta
          property="og:description"
          content="Discover the latest changes, new features, and improvements on Earf. Stay informed on how we're continuously enhancing the platform for a better user experience."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/changelog" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Changelog | Earf" />
        <meta
          name="twitter:description"
          content="Get the latest updates on Earf. Explore new features, improvements, and enhancements designed to improve your live streaming experience."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Changelog" />
      </Helmet>

      {/* Header Section with Back Button and Title */}
      <div className="changelog-header-container">
        <h1 className="changelog-title">
          <img src="/Earf-E.png" alt="Earf Logo" className="contact-logo" />{" "}
          Changelog
        </h1>
      </div>
      <div className="changelog-version-info">
        <p>
          <strong>Minor Updates:</strong> Incremental changes such as bug fixes, optimizations, or small feature additions (e.g., 1.0.1).<br />
          <strong>Major Updates:</strong> Significant feature releases, architectural changes, or major improvements (e.g., 1.2.0).<br />
          <strong>Release Versions:</strong> Denote stable, production-ready builds or critical milestones, including Release Candidates (RC) and Full Releases (e.g., 2.0.0).
        </p>
      </div>

      {changelogs.map((log, index) => (
        <div key={index} className="changelog-entry">
          <div className="changelog-header" onClick={() => toggleExpand(index)}>
            <h3>{log.title}</h3>
            <p>{log.date}</p>
            <button className="expand-btn">
              {expandedIndex === index ? "Collapse" : "Expand"}
            </button>
          </div>
          {expandedIndex === index && (
            <div className="changelog-details">
              <ul>
                {log.changes.map((change, idx) => (
                  <li
                    key={idx}
                    dangerouslySetInnerHTML={{ __html: change }}
                  ></li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Changelog;
